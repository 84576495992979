import React from "react";

function Ticket({ ticketNo, waitingTime }) {
  const TicketNumber = ticketNo.map((item) => item?.orderBookEntity.token);
  
  return (
    <div className="flex flex-col items-center justify-cente my-5">
      {/* Ticket Container */}
      <div className="w-[70%] sm:w-5/6 lg:w-[250px] bg-gradient-to-r from-red-500 to-red-700 p-4 rounded-lg shadow-lg">
        <div className="border-2 border-white rounded-md w-full h-full flex flex-col items-center justify-center overflow-hidden p-3">
          {/* Ticket Number */}
          <p className="z-20 text-xl font-bold text-white mb-2">
            {TicketNumber && TicketNumber.length > 0 ? TicketNumber[0] : "N/A"}
          </p>
          {/* Label */}
          <span className="w-full text-center text-lg text-white bg-white/20 rounded-md py-1 mt-1">
            Ticket No.
          </span>
        </div>
      </div>
      
      {/* Waiting Time */}
      <p className="mt-4 text-center text-lg font-semibold text-gray-700">
        Waiting Time: {handleTimeConvertion(waitingTime)}
      </p>
    </div>
  );
}

export default Ticket;

export function handleTimeConvertion(timeInMinutes) {
  if (typeof timeInMinutes !== 'number') return "";
  let valueTime = Math.abs(timeInMinutes);
  let hours = Math.floor(valueTime / 60); // Calculate the full hours
  let minutes = valueTime % 60; // Calculate the remaining minutes after converting to hours

  return `${hours} hr ${minutes} min`;
}
