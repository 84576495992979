import React from "react";
import Select, { SingleValue } from "react-select"; // Import SingleValue for type definition
import { IConfigServiceData } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { useSelector } from "react-redux";
import { RState } from "../../../redux/feature/slice";
import { useLocation } from "react-router-dom";

export interface IServiceDataLProps {
  serviceListData?: IConfigServiceData | null;
  checked?: number | undefined;
  setChecked?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function SelectService({ checked, setChecked }: IServiceDataLProps) {
  const serviceCategoryData = useSelector(
    (state: RState) => state?.services?.serviceCategories?.content
  );

  const location = useLocation();
  const gender = location.pathname.split("/")[3];

  // Filter the services based on gender
  const filteredServices = serviceCategoryData?.filter((item) => {
    const genderFormat =
      gender === "male" ? "Male" : gender === "female" ? "Female" : " ";
    return item.gender === genderFormat;
  });

  if (!filteredServices || filteredServices.length === 0) return null;

  // Create options for react-select ensuring value is of type number (not undefined)
  const options = filteredServices.map((item) => ({
    value: item.id as number, // Ensuring value is always a number
    label: item.categoryName,
  }));

  // Updated handleChange function with correct typing
  const handleChange = (
    selectedOption: SingleValue<{ value: number; label: string }>
  ) => {
    if (setChecked) {
      setChecked(selectedOption ? selectedOption.value : undefined);
    }
  };

  return (
    <div className="w-full">
      <p className="font-semibold italic mb-2 text-lg">Select a Service</p>
      <Select
        options={options}
        value={options.find((option) => option.value === checked) || null}
        onChange={handleChange}
        isClearable
        placeholder="Select a service..."
        className="w-full"
      />
    </div>
  );
}

export default SelectService;
