import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../../../hooks/useAxiosPrivate";
import { LOGBOOK_ENDPOINT } from "../../../../api/salon-api/log-book-service";
import { truncateText } from "../../../../helper/Common/text";
import { CustomizedDialog } from "../../../../components/Mui/feedback/Dialog";
import HistoryIcon from "@mui/icons-material/History";
const OrderHistory = () => {
  const [Services, setServices] = React.useState(null);

  const customerId = useSelector((state) => state?.CustomerCred?.token?.id);
  const salonId = useSelector((state) => state.auth.data.salonId);
  const axiosPrivate = useAxiosPrivateLogBook();
  const getOrderBookByCustomerId = async () => {
    try {
      const response = await axiosPrivate({
        method: "POST",
        url: `${LOGBOOK_ENDPOINT}`,
        data: {
          customerId: customerId,
          salonId: salonId,
        },
      });
      setServices(response.data.content);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getOrderBookByCustomerId();
  }, []);

  if (!Services) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  //    filter data based on complected
  const filterData = Services.filter((item, index, self) => {
    //also by date
    const date = new Date(item?.startTime);
    const indexDate = new Date(self[index - 1]?.startTime);
    return item.status === "WAITING";
  });
  console.log("Services___", filterData);

  return (
    <div>
      <Stack pt={2}>
        <Typography variant="h5" component="p">
          Order History
        </Typography>
        <Stack display={"flex"} flexWrap={"wrap"}>
          <CommonRender
            services={filterData}
            sx={{
              flexWrap: "wrap",
            }}
          />
          ;
        </Stack>
      </Stack>
    </div>
  );
};

export default OrderHistory;

export const CommonRender = ({ services, sx = {} }) => {
  const [selectedService, setSelectedService] = React.useState(null);

  function formatToReadableDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long", // "long" for full month name (e.g., "September")
      day: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  }

  const handleDialogClose = () => {
    setSelectedService(null);
  };

  return (
    <Stack>
      {selectedService && (
        <CustomizedDialog
          openDialog={Boolean(selectedService)}
          handleCloseDialog={handleDialogClose}
          title={"Order Details"}
          // disableClose={buttonLoader}
          icon={<HistoryIcon />}
          maxWidth="sm"
          isActionButtonRequired={false}
        >
          <Stack direction="column" spacing={1}>
            {/* Service Start Time */}
            <Typography variant="body1">
              <strong>Service Date:</strong>{" "}
              {formatToReadableDate(selectedService?.startTime)}
            </Typography>

            {/* Staff Name */}
            <Typography variant="body1">
              <strong>Staff:</strong> {selectedService?.salonStaffEntity?.name}
            </Typography>

            {/* Service Name */}
            <Typography variant="body1">
              <strong>Service Name:</strong>{" "}
              {selectedService?.salonServicesEntity?.serviceName}
            </Typography>

            {/* Staff Rating */}
            <Typography variant="body1">
              <strong>Staff Rating:</strong>{" "}
              {selectedService?.salonStaffEntity?.overAllRating}
            </Typography>

            {/* Service Price */}
            <Typography variant="body1">
              <strong>Price:</strong> $
              {selectedService?.salonServicesEntity?.price}
            </Typography>
          </Stack>
        </CustomizedDialog>
      )}

      <Stack
        direction="row"
        sx={{ p: 2, gap: 2, overflow: "auto", justifyContent: "center", ...sx }}
      >
        {services.map((service) => {
          return (
            <Stack
              key={service.id}
              maxWidth={200}
              spacing={1}
              onClick={() => setSelectedService(service)}
              sx={{
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                bgcolor: "#f9f9f9", // Light background color
              }}
            >
              <Stack spacing={0.5} direction="row" alignItems={"center"}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  Service Name:
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {truncateText(service?.salonServicesEntity?.serviceName, 10)}
                </Typography>
              </Stack>

              <Stack spacing={0.5} direction="row" alignItems={"center"}>
                <Typography variant="body1" fontWeight="bold">
                  Service Date:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatToReadableDate(service?.startTime)}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

{
  /* <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell align="right">Staff Name</TableCell>
        <TableCell align="right">Service</TableCell>
        <TableCell align="right">Rating(5) </TableCell>
        <TableCell align="right">Price</TableCell>
      </TableRow>
    </TableHead>
    {filterData &&
      filterData.length > 0 &&
      filterData.map((item) => {
        return (
          <TableBody>
            <TableRow
              key={item?.salonStaffEntity?.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="item">
                {formatToReadableDate(item?.startTime)}
              </TableCell>
              <TableCell component="th" scope="item">
                {truncateText(item?.salonStaffEntity?.name, 10)}
              </TableCell>
              <TableCell align="right">
                {item?.salonServicesEntity?.serviceName}
              </TableCell>
              <TableCell align="right">
                {item?.salonStaffEntity?.overAllRating}
              </TableCell>
              <TableCell align="right">
                {item?.salonServicesEntity?.price}
              </TableCell>
            </TableRow>
          </TableBody>
        );
      })}
  </Table>
</TableContainer> */
}
