import React from "react";
import SectionLeft from "./SectionLeft";

function Category() {
  return (
    <div className="flex mt-20 items-center justify-center">
      <SectionLeft />
    </div>
  );
}

export default Category;
