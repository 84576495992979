import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import {
  GET_ALL_STAFF_FOR_SERVICE,
  CHANGE_STAFF_BY_ID,
  GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID,
} from "../../../api/salon-api/log-book-service";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { setOrderBook } from "../../../redux/feature/orderBookSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleTimeConvertion } from "../../../pages/Customer/Checkout/ticket";

export default function PopWindow({
  isOpen,
  handleIsOpen,
  setOrderBooks,
  orderBookId,
  staffId,
  setWaitingTime,
  OrderBook,
}) {
  const axiosPrivateLogBook = useAxiosPrivateLogBook();
  const axiosPrivate = useAxiosPrivateLogBook();
  const dispatch = useDispatch();
  const logBookId = useSelector((state) => state.OrderBook);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableStaff, setAvailableStaff] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const waitingTimeRef = useRef(0);
  function handleOpenSecondDialog() {
    setOpenSecondDialog(!openSecondDialog);
  }

  function handleCloseAll() {
    handleIsOpen();
    handleOpenSecondDialog();
  }

  const GetOrderBookDetails = async () => {
    try {
      let changedIds = [];
      changedIds.push(orderBookId);
      OrderBook?.content?.forEach((service) => {
        if (service?.isChanged) {
          changedIds.push(service.id);
        }
      });
      const response = await axiosPrivate({
        method: "GET",
        url: `${GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID}/${logBookId.id}`,
      });
      setOrderBooks({
        content: response.data.map((service) => {
          let isExist = changedIds.find((t) => t === service.id);

          if (isExist) {
            return {
              ...service,
              isChanged: true,
            };
          }
          return service;
        }),
      });
      dispatch(setOrderBook({ id: logBookId.id, data: [response.data] }));
    } catch (error) {
      console.error("Error fetching Order Book data:", error);
    }
  };

  const handleStylistChange = async (staffId) => {
    setLoading(!loading);
    try {
      const resp = await axiosPrivateLogBook({
        method: "PUT",
        url: `${CHANGE_STAFF_BY_ID}/${staffId}/${orderBookId}/${waitingTimeRef.current}`,
      });
      setWaitingTime(resp.data);
      GetOrderBookDetails(orderBookId);
      toast.success("Stylist Changed Successfully!");
      handleCloseAll();

      // updating the service data

      setLoading(false);
    } catch (error) {
      console.error("Error fetching Order Book data:", error);
      toast.error("Unable to Change Stylist!");
      handleCloseAll();
      setLoading(false);
    }
  };

  const getAllStaffForService = async () => {
    try {
      const response = await axiosPrivateLogBook({
        method: "GET",
        url: `${GET_ALL_STAFF_FOR_SERVICE}/${orderBookId}`,
      });
      setAvailableStaff(
        response.data.filter((item) => item.staffId !== staffId)
      );
    } catch (error) {
      console.error("Error fetching Order Book data:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getAllStaffForService();
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        maxWidth={"xl"}
        fullWidth
        onClose={handleIsOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" variant="subtitle2">
          Change Current Service Stylist
        </DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 3 }}>
          <Card
            sx={{ width: "50%", maxHeight: 333, backgroundColor: "#8000FF" }}
            onClick={handleOpenSecondDialog}
          >
            <CardActionArea>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  component="div"
                  color="white"
                >
                  Continue changing Stylist ?
                </Typography>
                <Typography gutterBottom variant="caption" color="whitesmoke">
                  We have already assigned a perfect service provider based on
                  their ratings and quality of service. Do you still want to
                  change?
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{
              width: "50%",
              border: "3px solid #8000FF",
              textAlign: "center",
            }}
            onClick={handleIsOpen}
          >
            <CardActionArea>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <Typography gutterBottom variant="subtitle1" component="div">
                  Continue with assigned Stylist
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSecondDialog}
        onClose={handleOpenSecondDialog}
        maxWidth={"md"}
        fullWidth
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Please Select your preferred Service Provider
        </DialogTitle>
        <DialogContent>
          <List sx={{ bgcolor: "background.paper" }}>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments"></IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={"Staff Name"} />
                <ListItemText primary={"Waiting Time"} />
              </ListItemButton>
            </ListItem>
            {availableStaff.length > 0 ? (
              availableStaff
                .filter((staff) => !staff.isChanged)
                .map((value, index) => {
                  const labelId = `checkbox-list-label-${value.staffName}`;
                  return (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="comments"
                        ></IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => {
                          setSelectedId(value.staffId);
                          waitingTimeRef.current = value.waitingTime;
                        }}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedId === value.staffId}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.staffName} />
                        <ListItemText
                          id={labelId}
                          primary={value.waitingTime ? handleTimeConvertion(value.waitingTime) : "NA"}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })
            ) : (
              <Typography>No Stylist available.</Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseAll}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => handleStylistChange(selectedId)}
            variant="contained"
            color="primary"
            autoFocus
          >
            Apply
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
