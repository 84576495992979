import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Button as MUIButton,
} from "@mui/material";
import { truncateText } from "../../../helper/Common/text";
import PopWindow from "../../../components/CustomerComponent/Checkout/PopWindow";

function ItemsView({
  data: serviceData,
  setOrderBooks,
  setWaitingTime,
  OrderBook,
}) {
  const [isChange, setIsChange] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const handleIsChange = (id) => {
    setOrderId(id);
    setIsChange(!isChange);
  };

  if (serviceData?.length === 0) {
    return (
      <Typography variant="h5" component="p" align="center" mt={4}>
        No Items
      </Typography>
    );
  }

  return (
    <>
      <Stack spacing={2}>
        <TableContainer
          style={{ marginLeft: 10, marginRight: 10 }}
          component={Paper}
          elevation={3}
        >
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Staff Name</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Service</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Rating(5)</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Price</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Gender</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceData.map((row, index) => {
                return (
                  <React.Fragment key={`${index} + ${Date.now()}`}>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        {truncateText(row?.salonStaffEntity?.name, 10) || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.salonServicesEntity?.serviceName || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.salonServicesEntity?.overAllRating || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        ₹{row?.salonServicesEntity?.price || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.salonServicesEntity?.gender || "0.00"}
                      </TableCell>
                      <TableCell align="center">
                        <MUIButton
                          variant="contained"
                          color={row.isChanged ? "success" : "primary"}
                          onClick={() => handleIsChange(row.id)}
                          disabled={row.isChanged}
                        >
                          {row.isChanged ? "Changed" : "Change"}
                        </MUIButton>
                      </TableCell>
                    </TableRow>
                    <PopWindow
                      isOpen={isChange}
                      handleIsOpen={handleIsChange}
                      orderBookId={orderId}
                      setOrderBooks={setOrderBooks}
                      staffId={row?.salonStaffEntity?.id}
                      setWaitingTime={setWaitingTime}
                      OrderBook={OrderBook}
                    />
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}

export default ItemsView;

// import React, { useEffect, useState } from "react";
// import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
// import { Button as MUIButton, Typography } from "@mui/material";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Stack from "@mui/material/Stack";
// import { styled } from "@mui/material/styles";
// import PopWindow from "../../../components/CustomerComponent/Checkout/PopWindow";
// import { truncateText } from "../../../helper/Common/text";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import { useSelector } from "react-redux";
// import { LOGBOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
// import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
// function ItemsView({ data: serviceData, setOrderBooks }) {
//   const [isChange, setIsChange] = useState(false);
//   const [orderId, setOrderId] = useState(null);

//   const handleIsChange = (id) => {
//     setOrderId(id);
//     setIsChange(!isChange);
//   };

//   if (serviceData?.length === 0) {
//     return (
//       <div>
//         <Typography variant="h5" component="p">
//           No Items
//         </Typography>
//       </div>
//     );
//   }
//   return (
//     <>
//       <Stack>
//         <Typography variant="h5" component="p">
//           Items
//         </Typography>
//         <Stack display={"flex"} flexWrap={"wrap"}>
//           <TableContainer component={Paper}>
//             <Table sx={{ minWidth: 650 }} aria-label="simple table">
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Staff Name</TableCell>
//                   <TableCell align="center">Service</TableCell>
//                   <TableCell align="center">Rating(5) </TableCell>
//                   <TableCell align="center">Price</TableCell>
//                   <TableCell align="center">Gender</TableCell>
//                   <TableCell align="center">Action</TableCell>
//                 </TableRow>
//               </TableHead>
//               {serviceData &&
//                 serviceData?.length > 0 &&
//                 serviceData?.map((item) => {
//                   return (
//                     <>
//                       <TableBody>
//                         <TableRow
//                           key={item?.salonStaffEntity?.name}
//                           sx={{
//                             "&:last-child td, &:last-child th": { border: 0 },
//                           }}
//                         >
//                           <TableCell component="th" scope="item">
//                             {truncateText(item?.salonStaffEntity?.name, 10)}
//                           </TableCell>
//                           <TableCell align="right">
//                             {item?.salonServicesEntity?.serviceName}
//                           </TableCell>
//                           <TableCell align="right">
//                             {item?.salonStaffEntity?.overAllRating}
//                           </TableCell>
//                           <TableCell align="right">
//                             {item?.salonServicesEntity?.price}
//                           </TableCell>
//                           <TableCell align="right">
//                             {" "}
//                             {item?.salonStaffEntity?.gender}
//                           </TableCell>
//                           <TableCell align="right">
//                             <MUIButton
//                               onClick={handleIsChange.bind(null, item.id)}
//                               variant="contained"
//                               color="primary"
//                             >
//                               Change Stylist
//                             </MUIButton>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                       <PopWindow
//                         isOpen={isChange}
//                         handleIsOpen={handleIsChange}
//                         orderBookId={orderId}
//                         setOrderBooks={setOrderBooks}
//                         staffId={item?.salonStaffEntity?.id}
//                       />
//                     </>
//                   );
//                 })}
//             </Table>
//           </TableContainer>
//         </Stack>
//       </Stack>
//     </>
//   );
// }

// export default ItemsView;
