import React, { useState, useEffect } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { IServiceDataLProps } from "./SelectService";
import { IMG_URL } from "../../../constants/baseURL";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedService,
  removeSelectedService,
} from "../../../redux/feature/selectedService";
import useAxiosPrivate, {
  useAxiosPrivateLogBook,
} from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON } from "../../../api/salon-api/log-book-service";
import { RState } from "../../../redux/feature/slice";
import { ALL_SERVICE_BY_SERVICE_CATEGORY_ID } from "../../../api/salon-api/salon-service";
import NoStaffModal from "./NoStaffModal";
import { toLowerCaseBase } from "../../../helper/Common/text";
import { useLocation } from "react-router-dom";

// Utility function to format date
const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

interface ISelectedService {
  salonServicesId: number;
  cost: number;
  startTime: string;
  status: number;
  serviceName: string;
}

interface RootState {
  selectedService: {
    services: ISelectedService[];
  };
  setChecked?: React.Dispatch<React.SetStateAction<number | undefined>>;
  checked?: number | undefined;
}

const ServiceCard = ({
  serviceListData,
  setChecked,
  checked,
}: IServiceDataLProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(!open);
  const selectedServices = useSelector(
    (state: RootState) => state.selectedService.services
  );
  const SalonData = useSelector(
    (state: RState) => state.SalonDetails?.Salon_data
  );

  const [addedServices, setAddedServices] = useState<number[]>([]);
  const axiosPrivate = useAxiosPrivateLogBook();
  const axiosPrivateSalon = useAxiosPrivate();
  const SalonId = useSelector(
    (state: any) => state?.SalonDetails?.Salon_data?.id
  );

  const [filteredData, setFilteredData] = useState(serviceListData);

  const location = useLocation();

  const lastSegment = location.pathname.split("/")[3];

  const handleRemoveServices = (salonServiceId: number | undefined) => {
    if (salonServiceId !== undefined) {
      dispatch(removeSelectedService(salonServiceId));
    }
  };

  const handleSelectedService = async (
    salonServiceId: number | undefined,
    serviceName: string,
    price: number
  ) => {
    if (salonServiceId !== undefined) {
      const payload = {
        salonServicesId: salonServiceId,
        cost: price,
        startTime: formatDate(new Date()), // Use the utility function here
        status: 1,
        serviceName: serviceName,
      };
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: `${CHECK_STAFF_FOR_SERVICE_AVAILABLE_IN_SALON}/${salonServiceId}`,
        });
        if (response.data) {
          dispatch(addSelectedService(payload));
        } else {
          toast.warning("This Service is currrently unavailable !");
        }
      } catch (error) {
        console.error("Error Checking Available Staff for service", error);
        toast.error("Failed to process. Please try again.");
      }
    }
  };

  const fetchFilterData = async (id: number | undefined) => {
    if (!id) {
      setFilteredData(serviceListData);
      return;
    }
    const result = await axiosPrivateSalon({
      method: "GET",
      url: `${ALL_SERVICE_BY_SERVICE_CATEGORY_ID}/${id}/${SalonId}`,
    });
    console.log("result", result);
    setFilteredData({ ...result.data, content: result.data });
  };

  useEffect(() => {
    fetchFilterData(checked);
  }, [checked]);

  console.log("addedServices: ", serviceListData, selectedServices);

  const segFilter = filteredData?.content?.filter((item) => {
    return (
      toLowerCaseBase(item.gender) === toLowerCaseBase(lastSegment) &&
      item.status === "ACTIVE"
    );
  });

  return (
    <div className="pb-[30%] md:pb-[20%] xl:pb-[10%]">
      {!segFilter?.length && (
        <div className="p-2 w-full bg-4 rounded-lg flex-col sm:flex-row flex sm:overflow-hidden my-6">
          <h3 className="text-2xl font-semibold">
            No Service Available for {toLowerCaseBase(lastSegment)}
          </h3>
        </div>
      )}
      {segFilter &&
        segFilter.map((item, index) => {
          const { id, imageS3path, serviceName, price, avgServeTime } = item;
          const isAdded = selectedServices.find(
            (service) => service.salonServicesId === id
          );

          return (
            <div
              className="p-4 w-full rounded-lg shadow-md border border-gray-200 my-4 flex justify-between items-center"
              key={index}
            >
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold">{serviceName}</h2>
                <p className="text-green-500 text-[18px]">Service Info</p>
                <ul className="list-disc ml-5 mt-2 text-gray-600 gap-3">
                  <li>₹{price}</li>
                  <li>{avgServeTime} min</li>
                  {item?.serviceCategory?.description && (
                    <li>{item?.serviceCategory?.description}</li>
                  )}
                </ul>
              </div>

              <div className="flex flex-col pt-[-20px]">
                <div className=" p-1 rounded-md text-center">
                  <img
                    className="rounded-full w-[100px] h-[100px]"
                    src={`${IMG_URL.BASE_SERVICE_IMG}${imageS3path}`}
                    alt={serviceName}
                  />
                </div>
                <button
                  className={`mt-2 py-2 rounded-md text-white ${
                    isAdded ? "bg-gray-500" : "bg-purple-600"
                  }`}
                  onClick={() =>
                    isAdded
                      ? handleRemoveServices(id)
                      : handleSelectedService(id, serviceName, price)
                  }
                >
                  {isAdded ? "Remove" : "Add"}
                </button>
              </div>
            </div>
          );
        })}

      <NoStaffModal open={open} handleOpen={handleOpen} />
    </div>
  );
};

export default ServiceCard;
