import React, { useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import StarsIcon from "@mui/icons-material/Stars";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useState } from "react";
import CartData from "../../../Data/services/cart.json";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useServiceCategory from "../../../hooks/api/useServiceCategory";
import SelectService from "./SelectService";
import { usePaginatedServices } from "../../SalonStaff/Admin/SystemManagement/ConfigureServices/configServiceApiHook";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { ORDER_BOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
import { setOrderBook } from "../../../redux/feature/orderBookSlice";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  CircularProgress,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomerRunningServices from "./CustomerRunningServices";

function Service() {
  const location = useLocation();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();

  const segment = location.pathname.split("/")[3];
  const Logo = useSelector((state: RootState) => state.UploadLogo.logo);
  const SelectedService = useSelector(
    (state: ISelectedService) => state.selectedService
  );
  const CustomerDetails = useSelector(
    (state: ICustomerToken) => state.CustomerCred
  );
  const Auth = useSelector((state: IAuthToken) => state.auth);

  const [checked, setChecked] = useState<number | undefined>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const SalonDetails = useSelector(
    (state: ISalonDetails) => state?.SalonDetails?.Salon_data
  );

  const [open, setOpen] = React.useState(false);

  const [menu, setMenu] = useState(false);

  const { serviceData, isLoading } = usePaginatedServices(1, 2, false);
  const { loadingServiceCategory, serviceListData } = useServiceCategory({
    filters: { gender: segment, status: "ACTIVE" },
  });

  const navigate = useNavigate();
  if (loadingServiceCategory || !serviceListData) return <div>Loading</div>;

  const handleCheckout = async () => {
    if (SelectedService.services.length > 0) {
      const payload = {
        customerId: CustomerDetails?.token.id,
        salonId: Auth?.data.salonId,
        paidAmount: 0.0,
        totalCost: 0.0,
        discount: 0.0,
        status: "ACTIVE",
        paymentType: "CASH",
        salonServicesId: SelectedService.services.map((item) => {
          return item.salonServicesId;
        }),
      };

      try {
        const response = await axiosPrivate({
          method: "POST",
          url: ORDER_BOOK_ENDPOINT,
          data: payload,
        });
        dispatch(setOrderBook({ id: response.data.id, data: [] }));
        navigate("/customer/checkout");
      } catch (error) {
        console.error("Error fetching Order Book data:", error);
        toast.error("Failed to process order. Please try again.");
      }
    } else {
      toast.warning("Please Select a Service");
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="h-screen w-screen overflow-hidden">
      <CustomerRunningServices />
      <div className="max-h-screen w-full grid relative pt-5 overflow-hidden">
        <div className="pt-2 pb-7 sticky top-0 z-10 bg-white h-fit">
          <SelectService
            serviceListData={serviceData}
            setChecked={setChecked}
            checked={checked}
          />
        </div>
        <div className="max-h-screen overflow-y-scroll pb-60">
          <ServiceCard
            serviceListData={serviceData}
            setChecked={setChecked}
            checked={checked}
          />
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 w-full bg-white p-3 flex items-center justify-center shadow-lg">
        <Badge
          badgeContent={SelectedService?.services?.length}
          color="primary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Button
            onClick={handleCheckout}
            variant="contained"
            className="w-full"
          >
            Checkout
          </Button>
        </Badge>
      </div>
    </div>
  );
}

export default Service;

interface RootState {
  UploadLogo: {
    logo: string;
  };
}

interface ISelectedService {
  selectedService: {
    services: [
      {
        salonServicesId: number;
        startTime: string;
        cost: number;
        status: number;
        serviceName: string;
      }
    ];
    totalPrice: number;
  };
}

interface ISalonDetails {
  SalonDetails: {
    Salon_data: {
      name: string;
    };
  };
}

interface ICustomerToken {
  CustomerCred: {
    token: token;
    isCustomer: boolean;
    statusCode: 200;
  };
}

interface token {
  id: number;
  customerName: string;
  uuid: string;
  mobileNo: number;
  email: string;
  gender: string;
  status: string;
  termAndCondition: string;
  salonId: number | null;
  dob: string;
  statusCode: number;
}

interface IAuthToken {
  auth: {
    email: string;
    token: string;
    data: {
      salonId: number;
      clientId: string;
      userRole: string;
      sub: string;
      iat: number;
      exp: number;
    };
    userRole: string;
    salonId: number;
    clientId: string;
  };
}
