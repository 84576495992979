import React, { useEffect } from "react";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { LOGBOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
import { useSelector } from "react-redux";
import { Typography, Stack, Box, CircularProgress } from "@mui/material";
import { truncateText } from "../../../helper/Common/text";

const CustomerRunningServices = () => {
  const customerId = useSelector((state) => state?.CustomerCred?.token?.id);
  const salonId = useSelector((state) => state.auth.data.salonId);

  const [logBookData, setLogBookData] = React.useState(null);

  const axiosPrivate = useAxiosPrivateLogBook();

  const getOrderBookByCustomerId = async () => {
    try {
      const response = await axiosPrivate({
        method: "POST",
        url: `${LOGBOOK_ENDPOINT}`,
        data: {
          customerId: customerId,
          salonId: salonId,
        },
      });
      // show only waiting and in-progress

      setLogBookData(response.data.content);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getOrderBookByCustomerId();
  }, []);

  console.log(logBookData, "running_services");

  if (!logBookData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const runningServices = logBookData.filter(
    (data) => data.status === "PROGRESS"
  );
  const waitingServices = logBookData.filter(
    (data) => data.status === "WAITING"
  );

  return (
    <div className="pt-5 px-2">
      {runningServices.length > 0 && (
        <RunningServices services={runningServices} />
      )}

      {waitingServices.length > 0 && (
        <WaitingServices services={waitingServices} />
      )}
    </div>
  );
};

const RunningServices = ({ services }) => {
  return (
    <div>
      <Typography component="h1" variant="h5">
        Running Services
      </Typography>
      <CommonRender services={services} />
    </div>
  );
};

const WaitingServices = ({ services }) => {
  return (
    <div>
      <Typography component="h1" variant="h5">
        Waiting Services
      </Typography>
      <CommonRender services={services} />
    </div>
  );
};

export const CommonRender = ({ services, sx = {} }) => {
  function formatTimeTo12Hour(timeString) {
    const date = new Date(timeString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${formattedMinutes} ${ampm}`;
  }

  return (
    <Stack>
      <Stack direction="row" sx={{ p: 2, gap: 2, overflow: "auto", ...sx }}>
        {services.map((service) => {
          return (
            <Stack
              key={service.id}
              maxWidth={200}
              spacing={1}
              sx={{
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                bgcolor: "#f9f9f9", // Light background color
              }}
            >
              <Stack spacing={0.5} direction="row" alignItems={"center"}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  Service Name:
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {truncateText(service?.salonServicesEntity?.serviceName, 10)}
                </Typography>
              </Stack>

              <Stack spacing={0.5} direction="row" alignItems={"center"}>
                <Typography variant="body1" fontWeight="bold">
                  Start Time:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatTimeTo12Hour(service?.startTime)}
                </Typography>
              </Stack>

              <Stack spacing={0.5} direction="row" alignItems={"center"}>
                <Typography variant="body1" fontWeight="bold">
                  End Time:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatTimeTo12Hour(service?.endTime)}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default CustomerRunningServices;
