import React, { useEffect, useState } from "react";
import DiscountIcon from "@mui/icons-material/Discount";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "./ticket";
import ItemsView from "./item-view";
import PaymentSummary from "./paymentSummary";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import { setOrderBook } from "../../../redux/feature/orderBookSlice";
import { Box, CircularProgress } from "@mui/material";

function Checkout() {
  const [OrderBook, setOrderBooks] = React.useState(null);
  const orderBookId = useSelector((state) => state.OrderBook);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();
  const [waitingTime, setWaitingTime] = useState(0);
  const getOrderBookByCustomerId = async () => {
    try {
      const response = await axiosPrivate({
        method: "GET",
        url: `v1/service-log-book/getAllLogBookByOrderBookId/${orderBookId?.id}`,
      });
      setOrderBooks({
        content: response.data,
      });
      dispatch(setOrderBook({ id: orderBookId?.id, data: [response.data] }));
      setWaitingTime(response?.data?.[0].orderBookEntity?.totalWaitingTime);
    } catch (error) {
      console.error("Error fetching Order Book data:", error);
    }
  };

  useEffect(() => {
    getOrderBookByCustomerId();
  }, []);

  if (!OrderBook) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (OrderBook.content.length === 0) {
    return (
      <div className="flex justify-center items-center text-center h-screen">
        <div className="text-2xl font-bold">
          <p>No items in your cart</p>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="">
            {OrderBook?.content && (
              <ItemsView
                setOrderBooks={setOrderBooks}
                data={OrderBook?.content}
                setWaitingTime={setWaitingTime}
                OrderBook={OrderBook}
              />
            )}
            <div className="px-5 py-5">
              {" "}
              <DiscountIcon fontSize="large" className="" /> Coupons & Offers{" "}
              <p className="">(currently unavailable)</p>
            </div>
          </div>

          <div className="">
            {OrderBook?.content && (
              <Ticket ticketNo={OrderBook?.content} waitingTime={waitingTime} />
            )}
          </div>
        </div>
        <PaymentSummary
          TotalPrice={
            OrderBook?.content?.reduce(
              (prev, current) => prev + (current?.cost || 0),
              0
            ) ?? 0
          }
          Discount={0}
        />

        <div className=" ">
          <p className=" ">Cancellation Policy</p>
          <p className="">
            Please pay the price only you are confirmed for the service at your
            own risk. Service payments will not be refunded
          </p>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
